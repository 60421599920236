import { useEffect } from "react";
import { useAppContext } from "fusion-context";
import { useBreakpoints } from "~/shared-components/BreakpointContext";
import getAssembleUrlWithParams from "~/shared-components/features/fronts/force-refresh/utilities/url-with-params";

const ONE_SECOND = 1000;
const ONE_MINUTE = ONE_SECOND * 60;
const FIVE_MINUTES = ONE_MINUTE * 5;

// This is used by the soft refresh function to prevent a refresh (e.g. bc a user is in the middle of a video)
// If we find that an fn has been set to an id inside the hpRefreshTests object, calling that fn
// will tell us if the reason for pause-refresh has been concluded. True = finished, False = still paused.
const getAreRefreshTestsFinished = () =>
  !Object.keys(window.TWP.hpRefreshTests).find(
    (id) =>
      window.TWP.hpRefreshTests.hasOwnProperty(id) &&
      !window.TWP.hpRefreshTests[id]()
  );

const getUrl = getAssembleUrlWithParams("");

const AutoUpdate = () => {
  const { isAdmin } = useAppContext();

  const hpBreakpoints = useBreakpoints();
  const mobileBreakpoint =
    hpBreakpoints.bp && (hpBreakpoints.sm || hpBreakpoints.xs);

  // This is the effect for the soft refresh that handles instantiating the global 'block refresh' vars and
  // sets up the event listeners for blur/tab hide and focus/tab show
  useEffect(() => {
    let retryTimeout;
    let notInFocusTime;

    // The "softRefreshFn" is set up so that when a user goes idle and returns (and is not in the middle of a video),
    // we trigger a page refresh so that they are not looking at stale content
    const softRefreshFn = async () => {
      // Calls any hpRefreshTests. If any return false we block auto refresh
      if (window && window.TWP && window.TWP.hpRefreshTests && !isAdmin) {
        if (!getAreRefreshTestsFinished()) {
          retryTimeout = setTimeout(softRefreshFn, FIVE_MINUTES);
        } else {
          window.location.replace(getUrl(new Date().getTime()));
        }
      }
    };

    window.TWP = window.TWP || {};
    window.TWP.hpRefreshTests = window.TWP.hpRefreshTests || {};

    if (!isAdmin && !mobileBreakpoint) {
      const handleBlur = () => {
        notInFocusTime = new Date().getTime();
      };
      const handleFocus = () => {
        const now = new Date().getTime();
        notInFocusTime = notInFocusTime || now;
        const timeoutInterval = window.refreshTimeoutInterval || FIVE_MINUTES;
        if (now > notInFocusTime + timeoutInterval) {
          softRefreshFn();
        }
      };

      const handleVisibilityChange = () =>
        document.visibilityState === "visible" ? handleFocus() : handleBlur();

      window.addEventListener("blur", handleBlur);
      window.addEventListener("focus", handleFocus);
      document.addEventListener("visibilitychange", handleVisibilityChange);
      return () => {
        clearTimeout(retryTimeout);
        window.removeEventListener("blur", handleBlur);
        window.removeEventListener("focus", handleFocus);
        window.removeEventListener("visibilitychange", handleVisibilityChange);
      };
    }
    return () => {
      clearTimeout(retryTimeout);
    };
  }, [isAdmin, mobileBreakpoint]);

  return <div data-id="auto-update" />;
};

export default AutoUpdate;
